export const environment = {
  production: true,
  linkdInUr_AccessToken:'https://www.linkedin.com/oauth/v2/',
  linkdInApi:'https://api.linkedin.com/v2/',
  firebase: {
  },
  debug: false,
  log: {
    auth: false,
    store: false,
  },

  smartadmin: {
    api: null,
    db: 'smartadmin-angular'
  },
  // API_END_POINT_ADMIN : 'https://fkbwl.api.io-market.net/api/v1/',
  API_END_POINT_BUSINESS : 'https://api-business.io-procurement.com/api/v1/',
  // API_END_POINT_ACCOUNT : 'https://fjy29.api.io-market.net/api/v1/',
  // LOGIN_URL:"https://accounts.io-market.net/#/auth/login/",
  // ADMIN_URL:"https://admin.io-market.net/#/",
  LOGIN_URL: {'io-market.net':'https://accounts.io-market.net/#/auth/login/',
    'epost.gate2b.net':'https://accounts.epost.gate2b.net/#/auth/login/',
  'xatena.io-market.net':'https://accounts.xatena.io-market.net/#/auth/login/'},
  ADMIN_URL:{'xatena.io-market.net':'https://admin.xatena.io-market.net/#/',
    'io-market.net':'https://admin.io-market.net/#/',
    'epost.gate2b.net':'https://admin.epost.gate2b.net/#/'},
  allowedRedirectPath:['business.io-market.net','contract.netgate.io-market.net','gate2b.io-market.net','fkbwl.api.io-market.net','dhdgu.api.io-market.net','fjy29.api.io-market.net','edi.epost.gate2b.net','accounts.epost.gate2b.net'],
  // profile_URL :"https://accounts.io-market.net/#/profile",
  profile_URL:{'io-market.net':'https://accounts.io-market.net/#/profile',
    'epost.gate2b.net':'https://accounts.epost.gate2b.net/#/profile',
    'xatena.io-market.net':'https://accounts.xatena.io-market.net/#/profile'},
  privateKey:{key:"MIIEpAIBAAKCAQEAx5DMt1FGNFvMerMldZeh7o8iPdSrknRV6kCJfTfA60lMsJtFRbgwmED6jdpXoYG+Zh+Lt9nsCm8Mkj2c8ZFa3Gx3ANVZAJqcz7ztR/HFxVw2VIDEbbpQuVfbbf9O1W4WzOgvuWidPNNXuY62WRNnE/H4MkYVE8Bh+dN/c3VQhGycbvmuYjV6cG/EQv21vrJkCn0dMAx/wzo9ZQyxcsU0jUEOJczbnQyr4UoYsWtNQIXpZTQStRR3pbvNeyP2Ab7zo1ridahE+UkETQclaif80fGZ/3DcMpCR1SD0mryUb8z3pAwrygzuORRjs0augbvhAZg2WjVvfWbVepgF3KBlbwIDAQABAoIBAHab6gAeLvKZiMt4RBBC4fxuV7SV/K8bs6B5YP3qMB/nPcxNTHumgENNg1zh42lbOnVp1FmeDLsay0bzlZ7BfFY7pPL62l4Qhpi1OfWjjZ7CMoxAx/5LWQT/YaxbrFgPiT+Z1BuHQMTQ+3eOiXQhowborlH68zX9Bn48JKjTldpNwg2YZvPeZidWGKhqkeQIgj/wsM0fGUl5aR+xqTRt5ZOydjITDXKFQh6Ms+lhW+w73AaKfVZ16VVWrBCXqIjnVM6HUuzSv2SeJmlHZetw9uvvHBDZf0kYzC3DLpTtyqLacoplKc3kxQiV/vJuiwubVgLqHDeEawqEH7jWjKJa1dECgYEA7ptjGuYk1l0H/fWQn0BlPRVZO/l37bbkzyYJZbRh2SAxYgeh+EluXviRXDKwcRSkCnyNSM6cDf33f5U9pw46mCCXZPvfoFaIRJ70SF/2CMtiXyiw+o1ykEBKr12LNiK829+NrAQph9CGaAhm5AmGDsjDN20192Kje2HJ4z5YX7cCgYEA1hzd9HVA9EYCylPzc4BeeZ191ltd053p2a8PVJ2k2cToOcnBz+81MOfPKiyNzfVEdgSS95yCjHRP0CkViVsp/UpVHhVhRYgDU5nskKMfHvu1VG6RPjnWBhzWIwJrm+E6yxl0/NMuLkqQk14tl2NhQqq3kgs/D0TuJks2o6QTOAkCgYBCJWF7+4EKzA+DH6hP/ZITNO/f6HCOvb0AkBLa1v6pKnuGKYeFRRYLzey+Gm8/afFv1sdz/5LTbixGeiD1RsSHl8FghVKhKFvuagJebfvOxFMo/ibRzpSL2MbbhpspDsg8S+UCYsUAmZYpEOs1HE7FmnJD00q6oeWgNqO5E3d8UQKBgQCNx72986rYVkfgvKiYEif9NimuuP+vcoD4sbBgIqeXwIjaQ1yAmXDKeqW20pvwBvqXf73mExBre1WijKIDO4fZftBQRG4OAdvaDzIUMMAZ3ymayECLSPcNQykVM7QYG5O4C5ppFFofMyb7PsDH33n3EF7QaXwPW2CroOCEidBkKQKBgQDnF4bqesdBbtlET7ZnxDtaCJw/kZg1SFdeJWDUCNDOKmMu5zCdoisDFjvt5qLIIPPDPiwxf0hQXGJl76afE9rz03IYC98DtjrKxNmJzH8832PcZgDE4Z//8Bjn4ht+WZN7zcMcpXftrMKVtMLkQCSeBOMaBz4qUj3FcgqOVSKMSA=="},
  encryptionKey : "RdAO2InTo6jl4ArLYyIInQ==",
  cookieDomain:".io-market.net",
  checkCookie:"expireCookie",
  tokenExpireTime:"cookieCreatedTime",

   //LinkdIn
   client_id:"7750r8oy6j8tjb",
  scope:"r_liteprofile%20r_emailaddress",
  redirect_uri:"https://accounts.io-market.net/#/auth/socialLogin",
  grant_type:"authorization_code",
  client_secret:"y9ZnPaT08NlvyKT8",


  // Google
  gClient_id:'28645529457-t1pkd59o7v53hjv0a95tdtj18lnea9vj.apps.googleusercontent.com',
  cookiepolicy: 'single_host_origin',
  gScope:'profile email',
  stripePK:"pk_test_51JPR33HCmdVWPntTtDn8z71SB3EJOhgvYMM33olpRJi1qtNW8T79BfFT63UT0C9esWVkANaEaLWB8ca15inthRN800MTvxgTol",
  // LOGOUT_URL :"https://accounts.io-market.net/#/auth/logout/",
  LOGOUT_URL: {'io-market.net':'https://accounts.io-market.net/#/auth/logout',
    'epost.gate2b.net':'https://accounts.epost.gate2b.net/#/auth/logout/',
    'xatena.io-market.net':'https://accounts.xatena.io-market.net/#/auth/logout/'},
  domain:'.io-market.net',
  languageCookie:'languageCookie',
  styleCookie:'styleCookie',
  // ACCOUNTS_URL:'https://accounts.io-market.net/#/',
  WC_APPS:"https://wc.io-market.net/subscribedApps/main.js",
  WC_PROFILE:"https://wc.io-market.net/profile/main.js",
  USER_ACTIVE_CHECK:"isUserActive",
  userInfo:"userInfo",
  BEXIO_URL: "https://bexio.io-market.net/#/",
  MTC_URL: "https://mtc.io-market.net/#/",
  mtc_cookie: "mtc_cookie",
  permissions: 'permissions',
  color:"colorChangeTest",
  tax:"0.081",
  EBILL_ERROR_PRICE:"0.20",
  // GATE2B_URL:"https://gate2b.io-market.net/#/",
  GATE2B_URL:{'io-market.net':'https://gate2b.io-market.net/#/',
    'epost.gate2b.net':'https://edi.epost.gate2b.net/#/'},
  cookieConsent: 'cookieConsent',

  API_END_POINT_ACCOUNT : {'xatena.io-market.net':'https://fjy29.api.xatena.io-market.net/api/v1/',
  'io-market.net':'https://fjy29.api.io-market.net/api/v1/',
  'epost.gate2b.net':'https://fjy29.api.epost.gate2b.net/api/v1/'},
  STYLES : {'xatena.io-market.net':[{'--theme-color-1':'#e0b310'},{'--header-color':'#e0b31066'}],
  'io-market.net':[{'--theme-color-1':'#8B9635'},{'--header-color':'#474d226e'}],
  'epost.gate2b.net':[{'--theme-color-1':'#ffcc00'},{'--header-color':'transparent'}]},
  LOGO_ORIGINAL : {'xatena.io-market.net':'Xatena.png',
  'io-market.net':'logo.png',
  'epost.gate2b.net':'e-plus-p-logo.png'},
  LOGO : {"dark":{'io-market.net': "logo.png",'xatena.io-market.net': "Xatena_dark.png","epost.gate2b.net": "e_plus_logo_final.png"},
      "light":{ 'io-market.net': "logoWhite.png",'xatena.io-market.net': "Xatena_white.png","epost.gate2b.net": "e_plus_logo_final.png"}},
  FAVI : {'xatena.io-market.net':[{"icon":'xatena.ico',"title":"accounts.whitelabel.io-market.net"}],
  'io-market.net':[{"icon":'favicon_1.ico',"title":"accounts.io-procurement.com"}],
  'epost.gate2b.net':[{"icon":'ePost Logo Icon.svg',"title":"accounts.epost.gate2b.net"}]},
  FOOTER_TEXT : {'xatena.io-market.net':'Xatena',
  'io-market.net':'iomarket',
  'epost.gate2b.net':'iomarket'},
  API_END_POINT_ADMIN: {'xatena.io-market.net':'https://fkbwl.api.xatena.io-market.net/api/v1/',
  'io-market.net':'https://fkbwl.api.io-market.net/api/v1/',
  'epost.gate2b.net':'https://fkbwl.api.epost.gate2b.net/api/v1/'},
  ACCOUNTS_URL: {'xatena.io-market.net':'https://accounts.xatena.io-market.net/#/',
  'io-market.net':'https://accounts.io-market.net/#/',
  'epost.gate2b.net':'https://accounts.epost.gate2b.net/#/'},
  PROFILE_URL:"https://accounts.",
  HELP_DESK_CONTACT:  {
    "io-market.net": 'helpdesk@io-market.com',
    "epost.gate2b.net": 'pascal.leutenegger@epostservice.ch',
    "xatena.io-market.net": 'helpdesk@io-market.com'
  },
  SALES_TEAM_CONTACT:{
    "io-market.net": 'sales@io-market.com',
    "epost.gate2b.net": 'cristina.goncalves@epostservice.ch',
    "xatena.io-market.net": 'sales@io-market.com'
  },
  COMPANY_NAME: {
    'io-market.net':'iomarket AG',
    'epost.gate2b.net':'ePost Service AG'
  }
};
